import styled from 'styled-components'

const GovernanceContainer = styled.div`
  background-color: #fff;
  width: 100%;
  position: relative;
  padding: 52px 0;
  overflow: hidden;
  .governance__wrap {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 16px;
    position: relative;
  }
  .governance__title {
    margin: 8px 0;
    font-weight: 300;
    font-size: 32px;
    line-height: 1.2; // line-height: 46px;
    text-align: center;
    color: #000000;
    max-width: 990px;
    margin: 0 auto 56px;
    b {
      font-weight: 700;
    }
  }

  .governance__ods__description {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #000000;
    max-width: 510px;
    margin: 8px 0 24px;
    b {
      font-weight: 700;
    }
  }

  .governance__ods__list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
  }
  .governance__ods__item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(20% - 5px);
    @media (max-width: 1200px) {
      width: calc(25% - 5px);
    }
    @media (max-width: 1000px) {
      width: calc(33.333% - 5px);
    }
    @media (max-width: 767px) {
      width: calc(20% - 5px);
    }
    @media (max-width: 600px) {
      width: calc(25% - 5px);
    }
    @media (max-width: 500px) {
      width: calc(33.333% - 5px);
    }
    @media (max-width: 400px) {
      width: calc(50% - 5px);
    }
    &--disabled {
      opacity: 0.25;
      /* position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(217, 217, 217, 0.8);
      } */
    }
    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  .governance__indicator {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #bababa;
  }
  .governance__indicator__number {
    font-weight: 900;
    font-size: 60px;
    line-height: 70px;
    color: #614a94;
    margin: 8px 0;
    text-align: center;
  }
  .governance__indicator__description {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #000000;
    margin: 8px 0;
    text-align: center;
  }
  .governance__indicator__logos {
    display: flex;
    gap: 40px;
    margin: 40px 0 40px;
  }
  .governance__indicator__ref {
    position: absolute;
    bottom: 0;
    left: 16px;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
    margin: 8px 0;
  }

  .governance__frame__top,
  .governance__frame__bottom {
    font-size: 69px;
    position: absolute;
  }
  .governance__frame__top {
    top: -10px;
    left: -7px;
    color: #62970c;
  }
  .governance__frame__bottom {
    right: 5px;
    bottom: 0;
    transform: scaleX(-1) rotate(-90deg);
    color: #089dd0;
  }
`

export default GovernanceContainer
