import Icon from '@ant-design/icons'

function IconRhombus(props) {
  const iconSvg = () => (
    <svg width='1em' height='1em' viewBox='0 0 66 66' fill='currentColor'>
      <path
        opacity='0.4'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M33 -6.10352e-05L0 32.9999L33 65.9999L66 32.9999L33 -6.10352e-05ZM33 16.0002L16.0003 32.9999L33 49.9997L49.9997 32.9999L33 16.0002Z'
      />
    </svg>
  )

  return <Icon component={iconSvg} {...props} />
}

export default IconRhombus
