import styled from 'styled-components'

const FooterContainer = styled.div`
  .footer__links__wrap,
  .footer__compliance__wrap {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 16px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    @media (max-width: 767px) {
      flex-direction: column;
      gap: 32px;
    }
  }

  .footer__compliance__content,
  .footer__links__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 33.333%;
    @media (max-width: 767px) {
      width: 100%;
    }
    h2 {
      width: 100%;
      margin: 8px 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #ffffff;
    }
    a {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #ffffff;
      text-decoration: none;
      padding: 2px 5px;
      display: flex;
      gap: 8px;
      align-items: center;
    }
    svg {
      font-size: 22px;
    }
    img {
      width: 25px;
      height: auto;
    }
  }

  .footer__links {
    background-color: #0c0c0c;
  }

  .footer__compliance {
    background-color: #000000;
  }
  .footer__compliance__wrap {
    padding: 32px 16px;
  }
  .footer__compliance__content img {
    width: 16px;
  }
  .copyright {
    width: 100%;
    margin: 40px 0 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 46px;
    text-align: center;
    color: #ffffff;
  }
`

export default FooterContainer
