import { Button } from 'antd'
import { useIntl } from 'react-intl'
import IconDownload from '../CustomIcons/IconDownload'
import IconFrame from '../CustomIcons/IconFrame'
import BannerContainer from './style'
import IconRhombus from '../CustomIcons/IconRhombus'

function Banner() {
  const intl = useIntl()

  return (
    <BannerContainer id='inicio'>
      <div className='banner__container'>
        <div className='banner__content'>
          <IconRhombus
            style={{
              color: '#089DD0',
              fontSize: '61px',
              position: 'absolute',
              zIndex: '-1',
              top: '50%',
              left: '-70px',
            }}
          />
          <IconRhombus
            style={{ color: '#62970C', fontSize: '50px', position: 'absolute', zIndex: '-1', top: '0', right: '-50px' }}
          />
          <IconRhombus
            style={{ color: '#EA9618', fontSize: '34px', position: 'absolute', zIndex: '-1', top: '100%', left: '50%' }}
          />
          <IconRhombus
            style={{
              color: '#614A94',
              fontSize: '28px',
              position: 'absolute',
              zIndex: '-1',
              top: '100%',
              right: '-28px',
            }}
          />
          <IconFrame className='banner__frame__top' />
          <IconFrame className='banner__frame__bottom' />
          <h1>
            <span className='year'>2022</span>
            <span
              className='title'
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'banner.title' }) }}
            />
          </h1>
          <p>{intl.formatMessage({ id: 'banner.description' })}</p>
          <Button
            className='banner__download'
            icon={<IconDownload />}
            href={intl.formatMessage({ id: 'report.link' })}
            target='_blank'
            rel='noreferrer'
          >
            {intl.formatMessage({ id: 'banner.button' })}
          </Button>
        </div>
      </div>
    </BannerContainer>
  )
}

export default Banner
