import styled from 'styled-components'

const BrandsContainer = styled.div`
  background-color: #fff;
  width: 100%;
  position: relative;
  & > div {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    min-height: 190px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }
  a {
    display: block;
    padding: 5px;
  }
  img {
    max-width: 100%;
    height: 20px;
  }
`

export default BrandsContainer
