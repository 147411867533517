import { useIntl } from 'react-intl'
import Header from '../../components/Header'
import Brands from '../../components/Brands'
import Culture from '../../components/Culture'
import Appointments from '../../components/Appointments'
import Banner from '../../components/Banner'
import Footer from '../../components/Footer'
import SectionData from '../../components/SectionData'
import ImgHighlight from '../../assets/numbers/destaques-companhia.png'
import ImgResults from '../../assets/numbers/estrategia-sustentabilidade.png'
import ImgHighlightMob from '../../assets/numbers/destaques-companhia-mob.jpg'
import ImgResultsMob from '../../assets/numbers/estrategia-sustentabilidade-mob.jpg'
import Governance from '../../components/Governance'
import AnimationNumber from '../../components/AnimationNumber'

function Home() {
  const intl = useIntl()
  const highlightCover = { imgDesk: ImgHighlight, imgMob: ImgHighlightMob, imgAlt: 'Destaques da Companhia' }
  const highlightTitle = (
    <>
      {/* eslint-disable-next-line */}
      <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'sectionHighlight.title' }) }} />
    </>
  )
  const highlightNumbers = [
    {
      value: (
        <>
          <AnimationNumber numberValue='24' className='two-numbers' />{' '}
          {intl.formatMessage({ id: 'sectionHighlight.number01.pos' })}
        </>
      ),
      description: intl.formatMessage({ id: 'sectionHighlight.number01.description' }),
      key: 'number-item-0',
    },
    {
      value: (
        <>
          <AnimationNumber numberValue='146.1' numberDecimal className='five-numbers' />{' '}
          {intl.formatMessage({ id: 'sectionHighlight.number02.pos' })}
        </>
      ),
      description: intl.formatMessage({ id: 'sectionHighlight.number02.description' }),
      key: 'number-item-1',
    },
    {
      value: (
        <>
          <AnimationNumber numberValue='13.8' numberDecimal className='four-numbers' />{' '}
          {intl.formatMessage({ id: 'sectionHighlight.number03.pos' })}
        </>
      ),
      description: intl.formatMessage({ id: 'sectionHighlight.number03.description' }),
      key: 'number-item-2',
    },
  ]

  const resultsCover = {
    imgDesk: ImgResults,
    imgMob: ImgResultsMob,
    imgAlt: 'Garantindo os Resultados da Estratégia de Sustentabilidade',
  }
  const resultsTitle = (
    <span
      className='b-block'
      // eslint-disable-next-line
      dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'sectionResults.title' }) }}
    />
  )
  const resultsNumbers = [
    {
      value: (
        <>
          <AnimationNumber numberValue='30' className='two-numbers' />%
        </>
      ),
      description: intl.formatMessage({ id: 'sectionResults.number01.description' }),
      key: 'number-item-3',
    },
    {
      value: (
        <>
          R$
          <AnimationNumber numberValue='134' className='three-numbers' />
          {intl.formatMessage({ id: 'sectionResults.number02.pos' })}
        </>
      ),
      description: intl.formatMessage({ id: 'sectionResults.number02.description' }),
      key: 'number-item-4',
    },
    {
      value: (
        <>
          <AnimationNumber numberValue='1.6' numberDecimal className='three-numbers' />
          {/* eslint-disable-next-line */}
          <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'sectionResults.number03.pos' }) }} />
        </>
      ),
      description: intl.formatMessage({ id: 'sectionResults.number03.description' }),
      key: 'number-item-5',
    },
  ]

  return (
    <>
      <Header />
      <Banner />
      <Brands />
      <Culture />
      <div id='destaque'>
        <SectionData
          cover={highlightCover}
          title={highlightTitle}
          numbers={highlightNumbers}
          bg='#fff'
          color='#089DD0'
          coverPosition='left'
        />
        <SectionData
          cover={resultsCover}
          title={resultsTitle}
          numbers={resultsNumbers}
          bg='#F5F5F5'
          color='#62970C'
          coverPosition='right'
        />
      </div>
      <Governance />
      <Appointments />
      <Footer />
    </>
  )
}

export default Home
