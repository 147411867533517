import SectionDataContainer from './style'

function SectionData(props) {
  const { cover, title, numbers, bg, color, coverPosition } = props

  return (
    <SectionDataContainer style={{ backgroundColor: bg }} className={`sectiondata--${coverPosition}`}>
      <div className='sectiondata__wrap'>
        <div className='sectiondata__content'>
          <h2>{title}</h2>
          <div className='sectiondata__numbers__wrap'>
            {numbers.map((item) => (
              <div className='sectiondata__number' key={item.key}>
                <h3 style={{ color }}>{item.value}</h3>
                <p>{item.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <picture className='sectiondata__cover'>
        <source srcSet={cover.imgMob} media='(max-width: 500px)' />
        <img src={cover.imgDesk} alt={cover.imgAlt} />
      </picture>
    </SectionDataContainer>
  )
}

export default SectionData
