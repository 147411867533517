import styled from 'styled-components'

const SectionDataContainer = styled.div`
  background-color: #fff;
  width: 100%;
  position: relative;
  .sectiondata__cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 40%;
    height: 100%;
    @media (max-width: 767px) {
      position: relative;
      height: auto;
      width: auto;
      max-width: 500px; // max-width: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: right center;
    }
  }
  .sectiondata__wrap {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
  }
  .sectiondata__content {
    width: 60%;
    color: #fff;
    position: relative;
    margin-left: auto;
    padding: 100px 8px;
    @media (max-width: 767px) {
      width: 100%;
      padding: 24px 8px 32px;
    }
  }
  h2 {
    margin: 8px 0;
    font-weight: 300;
    font-size: 32px;
    line-height: 46px;
    text-align: center;
    color: #000000;
    .b-block b {
      display: block;
      margin: 0 auto;
    }
    b {
      font-weight: 700;
    }
  }

  .sectiondata__numbers__wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: center;
    margin-top: 45px;
    @media (max-width: 767px) {
      flex-direction: column;
      gap: 24px;
      margin-top: 24px;
    }
  }
  .sectiondata__number {
    h3 {
      margin: 0;
      font-weight: 900;
      font-size: 50px; // font-size: 61px;
      line-height: 71px;
      color: #089dd0;
      text-align: center;
      @media (max-width: 767px) {
        font-size: 38px;
      }
    }
    p {
      margin: 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #000000;
    }
  }

  &.sectiondata--right {
    .sectiondata__cover {
      right: 0;
      left: initial;
      margin-left: auto;
      img {
        object-position: left center;
      }
    }
    .sectiondata__content {
      margin-left: initial;
      margin-right: auto;
    }
  }

  .two-numbers {
    display: inline-block;
    min-width: 64px;
    text-align: right;
    @media (max-width: 767px) {
      min-width: 45px;
    }
  }
  .three-numbers {
    display: inline-block;
    min-width: 96px;
    text-align: right;
    @media (max-width: 767px) {
      min-width: 67px;
    }
  }
  .four-numbers {
    display: inline-block;
    min-width: 128px;
    text-align: right;
    @media (max-width: 767px) {
      min-width: 89px;
    }
  }
  .five-numbers {
    display: inline-block;
    min-width: 160px;
    text-align: right;
    @media (max-width: 767px) {
      min-width: 111px;
    }
  }
`

export default SectionDataContainer
