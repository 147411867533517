import antdPT from 'antd/lib/locale/pt_BR'
import enMessages from '../locales/pt_BR.json'

const PtLang = {
  messages: {
    ...enMessages,
  },
  antd: antdPT,
  locale: 'pt-BR',
}

export default PtLang
