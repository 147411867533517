import Icon from '@ant-design/icons'

function IconDownload(props) {
  const iconSvg = () => (
    <svg width='1em' height='1em' viewBox='0 0 11 14' fill='currentColor'>
      <path d='M9.10643 5.0357H7.85714V1.10713C7.85714 0.674983 7.50357 0.321411 7.07143 0.321411H3.92857C3.49643 0.321411 3.14286 0.674983 3.14286 1.10713V5.0357H1.89357C1.19429 5.0357 0.840714 5.88427 1.33571 6.37927L4.94214 9.9857C5.24857 10.2921 5.74357 10.2921 6.05 9.9857L9.65643 6.37927C10.1514 5.88427 9.80571 5.0357 9.10643 5.0357ZM0 12.8928C0 13.325 0.353571 13.6786 0.785714 13.6786H10.2143C10.6464 13.6786 11 13.325 11 12.8928C11 12.4607 10.6464 12.1071 10.2143 12.1071H0.785714C0.353571 12.1071 0 12.4607 0 12.8928Z' />
    </svg>
  )

  return <Icon component={iconSvg} {...props} />
}

export default IconDownload
