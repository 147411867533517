const opt = {
  pt: 'ptBR',
  es: 'esES',
  en: 'enUS',
}

const changeRybenaI18N = (lang = 'pt') => {
  window.RybenaApi?.getInstance().setLanguage(opt[lang])
}

export const insertRybena = (lang = 'pt') => {
  const script = document.createElement('script')

  script.src = `https://cdn.rybena.com.br/dom/master/latest/rybena.js?lang=${opt[lang]}`
  script.type = 'text/javascript'
  document.body.appendChild(script)
}

export default changeRybenaI18N
