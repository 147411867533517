import { Row, Col } from 'antd'
import { useIntl } from 'react-intl'
import Gri from '../../assets/ods/gri.png'
import Sasb from '../../assets/ods/sasb.png'
import GovernanceContainer from './style'
import IconFrameSecond from '../CustomIcons/IconFrameSecond'
import AnimationNumber from '../AnimationNumber'
import IconRhombus from '../CustomIcons/IconRhombus'

function Governance() {
  const intl = useIntl()
  const odsList = intl.messages['ods.list']

  return (
    <GovernanceContainer id='estrategia-e-governanca'>
      <div className='governance__wrap'>
        <IconRhombus
          style={{ color: '#EA9618', fontSize: '46px', position: 'absolute', zIndex: '0', top: '80px', right: '0' }}
        />
        <IconRhombus
          style={{ color: '#614A94', fontSize: '29px', position: 'absolute', zIndex: '0', top: '50%', left: '-29px' }}
        />
        <IconRhombus
          style={{
            color: '#E5302F',
            fontSize: '33px',
            position: 'absolute',
            zIndex: '0',
            bottom: '-40px',
            left: '50%',
          }}
        />
        <IconFrameSecond className='governance__frame__top' />
        <IconFrameSecond className='governance__frame__bottom' />
        <h2
          className='governance__title'
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'sectionGovernance.title' }) }}
        />
        <Row gutter={[32, 32]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <p
              className='governance__ods__description'
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'sectionGovernance.description' }) }}
            />
            <div className='governance__ods__list'>
              {odsList?.map((item) => (
                <div
                  key={`ods-item-${item.title}`}
                  className={
                    !item.active ? 'governance__ods__item governance__ods__item--disabled' : 'governance__ods__item'
                  }
                >
                  <img src={item.img} alt={item.title} />
                </div>
              ))}
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className='governance__indicator'>
            <h3 className='governance__indicator__number'>
              +<AnimationNumber numberValue='150' /> {intl.formatMessage({ id: 'sectionGovernance.number.pos' })}
            </h3>
            <p className='governance__indicator__description'>
              {intl.formatMessage({ id: 'sectionGovernance.number.description' })}
            </p>
            <div className='governance__indicator__logos'>
              <img src={Gri} alt='GRI' />
              <img src={Sasb} alt='SASB' />
            </div>
            <p className='governance__indicator__ref'>{intl.formatMessage({ id: 'sectionGovernance.ref' })}</p>
          </Col>
        </Row>
      </div>
    </GovernanceContainer>
  )
}

export default Governance
