import { useEffect, useState } from 'react'
import { OverPack } from 'rc-scroll-anim'
import TweenOne from 'rc-tween-one'
import ChildrenPlugin from 'rc-tween-one/lib/plugin/ChildrenPlugin'

function AnimationNumber(props) {
  TweenOne.plugins.push(ChildrenPlugin)
  const { numberValue, numberDecimal = false, className } = props
  const [animationParams, setAnimationParams] = useState()

  useEffect(() => {
    const params = { Children: { value: numberValue, floatLength: 0 }, duration: 2000, delay: 0 }
    if (numberDecimal) {
      params.Children.floatLength = 1
      params.Children.formatMoney = { decimal: ',' }
    }
    setAnimationParams(params)
  }, [numberDecimal])

  return (
    <OverPack playScale={[0.1, 0.1]} component='span' always={false} className={className}>
      <TweenOne component='span' yoyo animation={animationParams}>
        0
      </TweenOne>
    </OverPack>
  )
}

export default AnimationNumber
