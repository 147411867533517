import styled from 'styled-components'

const AppointmentsContainer = styled.div`
  background-color: #f5f5f5;
  width: 100%;
  position: relative;
  & > div {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 80px 16px;
  }
  .appointments__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 767px) {
      flex-direction: column;
    }
    & > div {
      padding: 16px;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .appointments__title {
    margin: 8px 0;
    font-weight: 300;
    font-size: 32px;
    line-height: 46px;
    text-align: center;
    color: #000000;
    b {
      font-weight: 700;
    }
  }
`

export default AppointmentsContainer
