import { createSlice } from '@reduxjs/toolkit'

export const settings = createSlice({
  name: 'settings',
  initialState: { lang: 'pt' },
  width: window.innerWidth,
  reducers: {
    setLang: (state, action) => ({ ...state, lang: action.payload }),
    updateWindowWidth: (state, action) => ({ ...state, width: action.payload }),
    clear: () => ({ lang: 'pt', width: window.innerWidth }),
  },
})

export const { setLang, updateWindowWidth } = settings.actions

export default settings.reducer
