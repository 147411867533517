import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { MenuOutlined } from '@ant-design/icons'
import { Anchor, Button, Drawer, Select } from 'antd'
import { useIntl } from 'react-intl'
import { setLang } from '../../store/Reducers/settings.reducer'
import Logo from '../../assets/logo-dexco.svg'
import IconDownload from '../CustomIcons/IconDownload'
import HeaderContainer, { HeaderMenuContainer, OptLangContainer } from './style'
import changeRybenaI18N from '../../utils/change-rybena-i18n'

function Header() {
  const enableI18N = true
  const intl = useIntl()
  const { lang } = useSelector((state) => state.settingsReducer)
  const dispatch = useDispatch()
  const [openSidebar, setOpenSidebar] = useState(false)
  // const [initRybera, setInitRybera] = useState(false)

  const menuItems = intl.messages['header.menu']

  const langOptions = [
    // {
    //   value: 'es',
    //   label: <OptLangContainer className='header__langs__es'>ES</OptLangContainer>,
    // },
    {
      value: 'en',
      label: <OptLangContainer className='header__langs__en'>EN</OptLangContainer>,
    },
    {
      value: 'pt',
      label: <OptLangContainer className='header__langs__pt'>PT</OptLangContainer>,
    },
  ]

  const showSidebar = () => {
    setOpenSidebar(true)
  }

  const hideSidebar = () => {
    setOpenSidebar(false)
  }

  useEffect(() => {
    if (!enableI18N) {
      dispatch(setLang('pt'))
    }
  }, [enableI18N])

  // useEffect(() => {
  //   // if (!initRybera) {
  //   //   insertRybena(lang)
  //   //   setInitRybera(true)
  //   // } else
  //     changeRybenaI18N(lang)
  // }, [lang])

  const menuContent = (anchorDirection = 'horizontal') => (
    <HeaderMenuContainer className={anchorDirection}>
      <Anchor
        className='header__menu'
        direction={anchorDirection}
        affix={false}
        items={menuItems}
        onClick={hideSidebar}
        offsetTop={74}
      />
      {enableI18N && (
        <Select
          className='header__langs'
          optionLabelProp='label'
          defaultValue={lang}
          options={langOptions}
          onChange={(value) => {
            changeRybenaI18N(value)
            dispatch(setLang(value))
          }}
        />
      )}
      <Button
        className='header__download'
        icon={<IconDownload style={{ color: '#FCFCFC' }} />}
        href={intl.formatMessage({ id: 'report.link' })}
        target='_blank'
        rel='noreferrer'
      >
        {intl.formatMessage({ id: 'header.download' })}
      </Button>
    </HeaderMenuContainer>
  )

  return (
    <HeaderContainer>
      <div className='header__fixed'>
        <div className='header__wrap'>
          <Button icon={<MenuOutlined />} className='header__menu__btn' onClick={showSidebar} />
          <a href='/' className='header__logo'>
            <img src={Logo} alt='Dexco' />
          </a>
          {menuContent()}
        </div>
      </div>
      <Drawer
        placement='left'
        onClose={hideSidebar}
        open={openSidebar}
        headerStyle={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        {menuContent('vertical')}
      </Drawer>
    </HeaderContainer>
  )
}

export default Header
