import styled from 'styled-components'

const CultureContainer = styled.div`
  background-color: #f5f5f5;
  width: 100%;
  position: relative;
  overflow: hidden;
  & > div {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    min-height: 190px;
    padding: 80px 16px;
    position: relative;
    z-index: 2;
  }

  .culture__title {
    margin: 8px 0;
    font-weight: 300;
    font-size: 32px;
    line-height: 46px;
    text-align: center;
    color: #000000;
    b {
      font-weight: 700;
    }
  }
  .culture__subtitle {
    margin: 8px 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #000000;
  }

  .culture__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    margin: 24px 0 0;
  }
  .culture__item {
    width: calc(25% - 8px);
    padding: 8px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 325px;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: transform 0.5s ease;
    overflow: hidden;
    @media (max-width: 767px) {
      width: calc(50% - 4px);
    }
    @media (max-width: 567px) {
      width: 100%;
    }
    & > span {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      transition: transform 0.5s ease;
      z-index: 0;
    }
    &:hover > span {
      transform: scale(1.2);
    }
    h3 {
      margin: 8px 0;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #ffffff;
      z-index: 1;
    }
    p {
      margin: 8px 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #ffffff;
      z-index: 1;
    }
    a {
      z-index: 1;
      padding: 5px;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      text-decoration-line: underline;
      color: #ffffff;
    }
  }
`

export default CultureContainer
