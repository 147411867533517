import styled from 'styled-components'
import IconPt from '../../assets/langs/idioma-pt-br.svg'
import IconEn from '../../assets/langs/idioma-en-us.svg'
import IconEs from '../../assets/langs/idioma-es-es.svg'

const HeaderContainer = styled.div`
  background-color: #fff;
  width: 100%;
  height: 75px;
  position: relative;
  .header__fixed {
    width: 100%;
    height: 75px;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    background: #fff;
  }
  .header__wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    padding: 0 16px;
    margin: 0 auto;
    gap: 65px;
    @media (max-width: 1200px) {
      gap: 15px;
    }
  }

  .header__menu__btn {
    position: absolute;
    left: 16px;
    border: 0;
    box-shadow: none;
    display: none;
    color: #000 !important;
    @media (max-width: 1100px) {
      display: block;
    }
  }
`

export const HeaderMenuContainer = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 65px;
  @media (max-width: 1200px) {
    gap: 15px;
  }
  @media (max-width: 1100px) {
    &.horizontal {
      display: none;
    }
    flex-direction: column;
    gap: 8px;
    background: #fff;
  }
  .header__menu {
    max-height: 100vh;
    flex: 1;
    height: 100%;
    @media (max-width: 1100px) {
      height: auto;
      width: 100%;
      flex: inherit;
    }
    &::before {
      display: none;
    }
  }
  .ant-anchor {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
    @media (max-width: 1100px) {
      display: block;
      height: auto;
    }
    &::before {
      display: none;
    }
  }
  .ant-anchor-ink {
    bottom: 15px !important;
    background-color: #000000 !important;
  }
  /* .ant-anchor-link {
    padding: 0 !important;
  } */
  .ant-anchor-link-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #595959;
    text-decoration: none;
    /* padding: 4px 8px; */
    &:hover,
    &.ant-anchor-link-title-active {
      /* font-weight: 500; */
      color: #000 !important;
    }
    @media (max-width: 1100px) {
      padding: 16px;
    }
  }

  .ant-select-selector {
    border-color: transparent !important;
    box-shadow: none !important;
  }

  .header__langs {
    @media (max-width: 1100px) {
      order: 3;
    }
  }

  .header__download {
    /* width: 189px; */
    height: 38px;
    background: #000000;
    border: 1px solid #000000;
    border-radius: 8px;
    color: #fff;
    line-height: 29px;
    &:hover {
      background-color: #fff;
      color: #000;
      border-color: #000;
      svg {
        color: #000;
      }
    }
    @media (max-width: 1100px) {
      order: 2;
    }
  }
`

export const OptLangContainer = styled.span`
  background-color: transparent;
  border: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 26px;
  color: #000000;
  cursor: pointer;
  padding: 5px 0; // padding: 5px 12px;
  &::before {
    content: '';
    width: 30px;
    height: 22px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }
  &.header__langs__es {
    &::before {
      background-image: url(${IconEs});
    }
  }
  &.header__langs__en {
    &::before {
      background-image: url(${IconEn});
    }
  }
  &.header__langs__pt {
    &::before {
      background-image: url(${IconPt});
    }
  }
`

export default HeaderContainer
