import { useIntl } from 'react-intl'
import CultureContainer from './style'
import IconRhombus from '../CustomIcons/IconRhombus'

function Culture() {
  const intl = useIntl()
  const cultureList = intl.messages['culture.list']

  return (
    <CultureContainer id='nosso-jeito-de-ser-e-fazer'>
      <div>
        <IconRhombus
          style={{ color: '#089DD0', fontSize: '34px', position: 'absolute', zIndex: '-1', top: '20px', left: '-16px' }}
        />
        <IconRhombus
          style={{
            color: '#E5302F',
            fontSize: '37px',
            position: 'absolute',
            zIndex: '-1',
            top: '20px',
            right: '-16px',
          }}
        />
        <IconRhombus
          style={{
            color: '#EA9618',
            fontSize: '46px',
            position: 'absolute',
            zIndex: '-1',
            bottom: '46px',
            left: '-46px',
          }}
        />
        <IconRhombus
          style={{
            color: '#62970C',
            fontSize: '36px',
            position: 'absolute',
            zIndex: '-1',
            bottom: '60px',
            left: '50%',
          }}
        />
        <h2
          className='culture__title'
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'culture.title' }) }}
        />
        <p className='culture__subtitle'>{intl.formatMessage({ id: 'culture.description' })}</p>
        <div className='culture__list'>
          {cultureList.map((item) => (
            <div className='culture__item' key={`culture-item-${item.title}`}>
              <span style={{ backgroundImage: `url(${item.img})` }} />
              <h3>{item.title}</h3>
              <p>{item.description}</p>
              {item.link && (
                <a href={item.link} {...(item.target && { target: item.target, rel: 'noreferrer' })}>
                  {intl.formatMessage({ id: 'culture.more' })}
                </a>
              )}
            </div>
          ))}
        </div>
      </div>
    </CultureContainer>
  )
}

export default Culture
