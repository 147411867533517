import Icon from '@ant-design/icons'

function IconFrameSecond(props) {
  const iconSvg = () => (
    <svg width='1em' height='1em' viewBox='0 0 69 100' fill='currentColor'>
      <path d='M0 0L69 0L55.1176 17.7951L18.0471 17.7951L18.0471 79.3637L0 100L0 0Z' />
    </svg>
  )

  return <Icon component={iconSvg} {...props} />
}

export default IconFrameSecond
