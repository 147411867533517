import { FacebookFilled, InstagramFilled, LinkedinFilled, YoutubeFilled, MailFilled } from '@ant-design/icons'
import { FormattedMessage, useIntl } from 'react-intl'
import IconNewPage from '../../assets/icons/icon-new-page.svg'
import IconSite from '../../assets/icons/icon-site.svg'
// import PrivacyPolicyWrap from '../PrivacyPolicy/PrivacyPolicyWrap'
import FooterContainer from './style'

function Footer() {
  const intl = useIntl()

  return (
    <FooterContainer>
      <div className='footer__links'>
        <div className='footer__links__wrap'>
          <div className='footer__links__content'>
            <h2>
              <FormattedMessage id='footer.follow' />
            </h2>
            <a href='https://www.facebook.com/dexco.oficial/' target='_blank' rel='noreferrer' title='Facebook Dexco'>
              <FacebookFilled />
            </a>
            <a href='https://www.instagram.com/dexco.oficial/' target='_blank' rel='noreferrer' title='Instagram Dexco'>
              <InstagramFilled />
            </a>
            <a href='https://www.linkedin.com/company/dexcosa/' target='_blank' rel='noreferrer' title='Linkedin Dexco'>
              <LinkedinFilled />
            </a>
            <a href='https://www.youtube.com/c/DEXCO/' target='_blank' rel='noreferrer' title='Youtube Dexco'>
              <YoutubeFilled />
            </a>
          </div>
          <div className='footer__links__content'>
            <h2>
              <FormattedMessage id='footer.site' />
            </h2>
            <a href='https://www.dex.co/esg/' target='_blank' rel='noreferrer'>
              <img src={IconSite} alt='Dexco' />
              <span>dex.co/esg</span>
            </a>
          </div>
          <div className='footer__links__content'>
            <h2>
              <FormattedMessage id='footer.contact' />
            </h2>
            <a href='mailto:investidores@dex.co'>
              <MailFilled />
              <span>investidores@dex.co</span>
            </a>
          </div>
        </div>
      </div>
      <div className='footer__compliance'>
        <div className='footer__compliance__wrap'>
          <div className='footer__compliance__content'>
            <a href={intl.formatMessage({ id: 'footer.termsLink' })} target='_blank' rel='noreferrer'>
              <span>
                <FormattedMessage id='footer.terms' />
              </span>
              <img src={IconNewPage} alt='Link' />
            </a>
          </div>
          <div className='footer__compliance__content'>
            <a href={intl.formatMessage({ id: 'footer.codeLink' })} target='_blank' rel='noreferrer'>
              <span>
                <FormattedMessage id='footer.code' />
              </span>
            </a>
          </div>
          <div className='footer__compliance__content'>
            <a href={intl.formatMessage({ id: 'app.privacy_policyLink' })} target='_blank' rel='noreferrer'>
              <span>
                <FormattedMessage id='app.privacy_policy' />
              </span>
            </a>
            {/* <PrivacyPolicyWrap /> */}
          </div>
          <p className='copyright'>
            <span>&copy; 2023 Dexco S.A</span>
          </p>
        </div>
      </div>
    </FooterContainer>
  )
}

export default Footer
