import { useSelector } from 'react-redux'
import { ConfigProvider } from 'antd'
import { IntlProvider } from 'react-intl'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import AppLocale from './lngProvider'
import Home from './pages/Home'

function App() {
  const { lang } = useSelector((state) => state.settingsReducer)
  const currentAppLocale = AppLocale[lang]

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
        {/* <div
          style={{
            padding: '16px',
            width: '100%',
            minHeight: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <h1>Relato Integrado 2022</h1>
          <p>Em breve</p>
        </div> */}
        <BrowserRouter>
          <Routes>
            <Route path='*' element={<Home />} />
          </Routes>
        </BrowserRouter>
      </IntlProvider>
    </ConfigProvider>
  )
}

export default App
