import Icon from '@ant-design/icons'

function IconFrame(props) {
  const iconSvg = () => (
    <svg width='1em' height='1em' viewBox='0 0 44 37' fill='currentColor'>
      <path d='M2.30378e-05 0L2.14205e-05 37L11.1855 28.2941L11.1855 11.3176L31.0287 11.3176L44 1.9233e-06L2.30378e-05 0Z' />
    </svg>
  )

  return <Icon component={iconSvg} {...props} />
}

export default IconFrame
