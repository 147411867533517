import { useIntl } from 'react-intl'
import LogoFsc from '../../assets/appointments/fsc.png'
import LogoCdp from '../../assets/appointments/cdp-en.png'
import LogoSpott from '../../assets/appointments/spott.png'
import LogoGhg from '../../assets/appointments/ghg.png'
import LogoIcon2b3 from '../../assets/appointments/icon2-b3.png'
import LogoIseb3 from '../../assets/appointments/ise-b3.png'
import AppointmentsContainer from './style'

function Appointments() {
  const intl = useIntl()
  const appointmentsList = [
    { title: 'FSC', logo: LogoFsc },
    { title: 'CDP', logo: LogoCdp },
    { title: 'SPOTT', logo: LogoSpott },
    { title: 'Programa Brasileiro GHG Protocol', logo: LogoGhg },
    { title: 'ICON2 B3', logo: LogoIcon2b3 },
    { title: 'ISE B3', logo: LogoIseb3 },
  ]

  return (
    <AppointmentsContainer id='compromissos-e-certificacoes'>
      <div>
        <h2
          className='appointments__title'
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'sectionAppointments.title' }) }}
        />
        <div className='appointments__list'>
          {appointmentsList.map((item) => (
            <div key={`brand-item-${item.title}`}>
              <img src={item.logo} alt={item.title} />
            </div>
          ))}
        </div>
      </div>
    </AppointmentsContainer>
  )
}

export default Appointments
