import LogoDeca from '../../assets/brands/deca.svg'
import LogoPortinari from '../../assets/brands/portinari.svg'
import LogoHydra from '../../assets/brands/hydra.svg'
import LogoDuratex from '../../assets/brands/duratex.svg'
import LogoCastelatto from '../../assets/brands/castelatto.svg'
import LogoCeusa from '../../assets/brands/ceusa.svg'
import LogoDurafloor from '../../assets/brands/durafloor.svg'
import BrandsContainer from './style'

function Brands() {
  const brandList = [
    { title: 'Deca', logo: LogoDeca, link: 'https://www.deca.com.br/' },
    { title: 'Portinari', logo: LogoPortinari, link: 'https://www.ceramicaportinari.com.br/' },
    { title: 'Hydra', logo: LogoHydra, link: 'https://www.hydra-corona.com.br/' },
    { title: 'Duratex', logo: LogoDuratex, link: 'https://www.duratexmadeira.com.br/' },
    { title: 'Castelatto', logo: LogoCastelatto, link: 'https://castelatto.com.br/' },
    { title: 'Ceusa', logo: LogoCeusa, link: 'https://www.ceusa.com.br/' },
    { title: 'Durafloor', logo: LogoDurafloor, link: 'https://www.durafloor.com.br/' },
  ]

  return (
    <BrandsContainer>
      <div>
        {brandList.map((item) => (
          <a href={item.link} target='_blank' rel='noreferrer' key={`brand-item-${item.title}`}>
            <img src={item.logo} alt={item.title} />
          </a>
        ))}
      </div>
    </BrandsContainer>
  )
}

export default Brands
