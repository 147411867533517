import enLang from './entries/en-US'
import esLang from './entries/es_ES'
import ptLang from './entries/pt_BR'

const AppLocale = {
  pt: ptLang,
  en: enLang,
  es: esLang,
}

export default AppLocale
