import styled from 'styled-components'
import ImgBanner from '../../assets/banner.png'

const BannerContainer = styled.div`
  background-color: #000000;
  width: 100%;
  position: relative;
  overflow: hidden;
  &::after {
    content: '';
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    background: url(${ImgBanner}) no-repeat left / cover;
    @media (max-width: 767px) {
      position: inherit;
      top: inherit;
      right: inherit;
      width: 100%;
      height: 300px;
    }
  }
  .banner__container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px 16px 50px;
    position: relative;
    z-index: 2;
  }
  .banner__content {
    width: 50%;
    color: #fff;
    position: relative;
    @media (max-width: 767px) {
      width: 100%;
    }
    h1 {
      line-height: 1;
      margin: 8px 0;
    }
    .year {
      font-size: 230px;
      display: block;
      line-height: 1;
      @media (max-width: 1200px) {
        font-size: 19vw;
      }
      @media (max-width: 767px) {
        font-size: 40vw;
      }
    }
    .title {
      font-weight: 300;
      font-size: 57px;
      @media (max-width: 1200px) {
        font-size: 4.6vw;
      }
      @media (max-width: 767px) {
        font-size: 9.5vw;
      }
      b {
        font-weight: 700;
      }
    }
    p {
      max-width: 520px;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      margin: 16px 0 32px;
      @media (max-width: 767px) {
        max-width: inherit;
      }
    }
  }
  .banner__download {
    height: 38px;
    background: #ffff;
    border: 1px solid #000000;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    color: #000000;
    &:hover {
      background-color: #000;
      color: #fff;
      border-color: #fff;
      svg {
        color: #fff;
      }
    }
  }

  .banner__frame__top,
  .banner__frame__bottom {
    color: #e5302f;
    font-size: 37px;
    position: absolute;
  }
  .banner__frame__top {
    top: 0;
    left: 0;
  }
  .banner__frame__bottom {
    right: 0;
    bottom: 0;
    transform: rotate(180deg);
  }
`

export default BannerContainer
